<script>
  export let legals;
</script>

<style lang="scss">@charset "UTF-8";
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
.footer {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 0.6rem;
  grid-row-gap: 0.6rem;
  align-content: start;
  padding-top: 2.85rem;
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  padding-bottom: 0.6rem;
}
@media (min-width: 1024px) {
  .footer {
    padding-top: 6rem;
  }
}

.menu {
  grid-column: span 3;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  position: relative;
}

.link {
  color: inherit;
  text-decoration: none;
  transition: color 0.1s ease;
}
.list:hover .link {
  opacity: rgb(179, 179, 179);
}
.list .link:hover {
  color: rgb(0, 0, 0);
}</style>

<footer class="footer">
  <nav class="menu">
    <ul class="list">
      {#each legals as legal}
        <li class="item">
          <a class="link" href="{legal.slug}">{legal.title}</a>
        </li>
      {/each}
    </ul>
  </nav>
</footer>
