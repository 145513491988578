<script>
  import MainMenu from '@gb/components/MainMenu.svelte';

  export let categories;
  export let pages;
  export let segment;
</script>

<style lang="scss">@charset "UTF-8";
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  margin-bottom: -0.45rem;
  padding-top: 0.45rem;
  padding-right: 0.6rem;
  padding-bottom: 0.45rem;
  padding-left: 0.6rem;
  background-color: rgb(247, 247, 247);
}
@media (min-width: 1024px) {
  .header::after {
    content: "";
    flex: 1;
  }
}

.menu-holder {
  position: relative;
  flex: 1;
}

.logo-holder {
  z-index: 5;
  flex: 1;
  padding-left: 3ch;
  text-align: right;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .logo-holder {
    padding-left: 0;
    text-align: center;
  }
}

.link {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}</style>

<header class="header">
  <nav class="menu-holder">
    <MainMenu {categories} {pages} {segment}/>
  </nav>
  
  <div class="logo-holder">
    <a href="/" class="link">gabriele boulanger</a>
  </div>
</header>
