<script>
  export let status;
  export let error;

  const dev = undefined === "development";
</script>

<style lang="scss">@charset "UTF-8";
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
.page {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 0.6rem;
  grid-row-gap: 0.6rem;
  align-content: start;
  margin-right: auto;
  margin-left: auto;
  max-width: 80rem;
  min-height: calc(100vh - 2.85rem - 2 * 1.2 * 1rem - 0.6rem);
  padding-top: 2.85rem;
}
@media (min-width: 1024px) {
  .page {
    padding-top: 6rem;
    min-height: calc(100vh - 6rem - 2 * 1.2 * 1rem - 0.6rem);
  }
}

.title {
  margin: 0;
  grid-column: span 12;
  font-size: 1rem;
  font-weight: 400;
}
@media (min-width: 1024px) {
  .title {
    grid-column: 2/span 5;
  }
}

.message {
  grid-column: span 12;
}
@media (min-width: 1024px) {
  .message {
    grid-column: 2/span 5;
  }
}</style>

<svelte:head>
  <title>fehler</title>
</svelte:head>

<main class="page">
  {#if dev && (error.message || error.stack)}
    <h1 class="title">{status}</h1>
    {#if error.message}
      <div class="message">
        {error.message}
      </div>
    {/if}

    {#if error.stack}
      <pre>{error.stack}</pre>
    {/if}

  {:else}
    <h1 class="title">fehler</h1>
    <div class="message">
      leider konnte die seite nicht geladen werden
    </div>
  {/if}
</main>
