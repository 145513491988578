<script>
  import { fade, slide } from 'svelte/transition';

  export let categories;
  export let pages;
  export let segment;

  let opened;

  // mark offset items
  categories[0].offset = true;
  pages[0].offset = true;

  const indexObject = { title: 'index', slug: '/' };

  // create array containing all menu items
  let menuItems = [indexObject, ...categories, ...pages];

  function open() {
    opened = true;
  }

  function close() {
    opened = false;
  }

  function handleClick(event, item) {
    // close the menu if the active link was clicked
    if (item === active && opened) {
      close();

    } else {
      if (item.slug === '/'
          && (segment === 'datenschutz'
              || segment === 'impressum')) {
        event.preventDefault();
      }

      open();
    }
  }

  // find the active menu item on segment change
  $: active = menuItems.find(item => item.slug.split('/')[0] === segment);

  // if no active item was found, use the index object
  $: if (!active) active = indexObject;

  // close the menu on active item change
  $: if (active) close();

  // handle cases where index should be active
  // $: if (!segment
  //        || segment === 'impressum'
  //        || segment === 'datenschutz') {
  //   segment = '';
  // }
</script>

<style lang="scss">@charset "UTF-8";
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  left: 0;
  max-width: 100%;
  background-color: rgb(247, 247, 247);
}
.list.opened {
  width: calc(100vw - 1.2rem);
  max-width: none;
}

.list.opened .item.offset {
  margin-top: 1.2rem;
}
.list.opened .item:last-child {
  margin-bottom: 0.6rem;
}

.link {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.1s ease;
}
.list:hover .link {
  color: rgb(179, 179, 179);
}
.list .link:hover {
  color: rgb(0, 0, 0);
}
.link.active::after {
  content: "↓";
  display: inline-block;
  margin-left: 0.35em;
  transform: rotate(0deg);
}
.opened .link.active::after {
  transform: rotate(180deg);
}

.text {
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}</style>

<ul class="list" class:opened>
  {#each menuItems as item}
    {#if item === active || opened }
      <li
        class="item"
        class:offset={item.offset}
        transition:slide={{ duration: 100 }}>
        <a
          class="link"
          class:active={item === active}
          href="{item.slug}"
          on:click={event => handleClick(event, item)}
          rel=prefetch>
            <div class="text">{item.title}</div>
        </a>
      </li>
    {/if}
  {/each}
</ul>
